<template>
    <div class="ec-edit-control-content">
        <div class="ec-image-container">
            <div class="ec-image-wrapper">
                <img class="edit-control-image" ref="image" />

                <div v-if="isProcessing" class="edit-control-overlay">
                    <div class="edit-control-spinner"></div>
                </div>
            </div>
        </div>
        <div class="ec-control-container" ref="controlview">
            <div>
                <h3>Farbanpassung</h3>
                <p>Wählen Sie die von Ihnen gewünschten Farben und Effekte für das Bild aus.</p>
                <br />
            </div>
            <div ref="controls" class="ec-controls-wrapper">
                <SliderControl
                    title="Helligkeit"
                    :min="brightnessMin"
                    :max="brightnessMax"
                    v-model.number="brightness"
                    @onValueChanged="invalidateImage()"
                    @onValueReset="invalidateImage()"
                />

                <SliderControl
                    title="Kontrast"
                    :min="contrastMin"
                    :max="contrastMax"
                    v-model.number="contrast"
                    @onValueChanged="invalidateImage()"
                    @onValueReset="invalidateImage()"
                />

                <SliderControl
                    title="Sättigung"
                    :min="saturationMin"
                    :max="saturationMax"
                    v-model.number="saturation"
                    @onValueChanged="invalidateImage()"
                    @onValueReset="invalidateImage()"
                />

                <SliderControl
                    title="Farbton"
                    :min="hueMin"
                    :max="hueMax"
                    v-model.number="hue"
                    @onValueChanged="invalidateImage()"
                    @onValueReset="invalidateImage()"
                />

                <CheckboxControl
                    ref="invertCheckbox"
                    title="Invertieren"
                    v-model="invert"
                    @onValueChanged="invalidateImage()"
                />
                <!-- <ButtonControl
                    title="Zurücksetzen"
                    text="Click"
                    @onClick="
                        setDefaultValues();
                        invalidateImage();
                    "
                /> -->
                <br />
                <div ref="colorSelectPane" v-for="n in wpAttributes.colorCount" :key="n">
                    <ColorSelectControl
                        ref="colorSelects"
                        :modelId="n - 1"
                        :title="'Farbe ' + n"
                        :colors="config.colors"
                        @onValueChanged="handleOnColorChange"
                    />
                </div>

                <!-- <div class="edit-control-testing">
                    <br />
                    <br />
                    <p>Debug Controls</p>
                    <br />
                    <p>
                        Dithering:
                        <select @change="invalidateImage" v-model="ditheringAlgorithm">
                            <option v-for="algorithm in ditheringAlgorithms" :key="algorithm" :value="algorithm">
                                {{ algorithm }}
                            </option>
                        </select>
                    </p>
                    <p>
                        <checkbox-control
                            title="Fullcolor"
                            v-model="disableQuantisation"
                            @onValueChanged="invalidateImage()"
                        />
                    </p>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from "vue";
import { ImageProcessor } from "../assets/js/ImageProcessor.js";
import SliderControl from "./SliderControl.vue";
import CheckboxControl from "./CheckboxControl.vue";
import ColorSelectControl from "./ColorSelectControl.vue";
import ButtonControl from "./ButtonControl.vue";

export default {
    name: "EditControl",
    components: { SliderControl, CheckboxControl, ColorSelectControl, ButtonControl },
    data() {
        return {
            ditheringAlgorithms: [
                "nearest",
                "riemersma",
                "floyd-steinberg",
                "false-floyd-steinberg",
                "stucki",
                "atkinson",
                "jarvis",
                "burkes",
                "sierra",
                "two-sierra",
                "sierra-lite",
            ],
            ditheringAlgorithm: "atkinson",

            brightnessMin: -100,
            brightnessMax: 100,
            brightness: 0,

            saturationMin: -100,
            saturationMax: 100,
            saturation: 0,

            contrastMin: -100,
            contrastMax: 100,
            contrast: 0,

            hueMin: -180,
            hueMax: 180,
            hue: 0,

            invert: false,
            selectedColors: [],

            originalImage: null,
            previewImage: null,
            distoredImage: null,
            disableQuantisation: false,

            isProcessing: false,
            designerVariant: null,
            imageWorker: null,

            isPortrait: false,
        };
    },
    
    setup() {
        const config = inject("config");
        const wpAttributes = inject("wpAttributes");

        return { config, wpAttributes };
    },

    mounted() {
        this.imageWorker = new ImageProcessor();
        this.imageWorker.createWorker();
        this.imageWorker.registerOnMessageCallback(this.handleOnImageProcessed);
    },
    
    methods: {
        async setOriginalImageBase64(base64) {
            this.originalImage = new Image();
            this.originalImage.src = base64;

            // if original image is new also set preview image
            this.setPreviewImageBase64(base64);
        },

        getOriginalImage() {
            return this.originalImage;
        },

        getOriginalImageBase64() {
            return this.originalImage.src;
        },

        setPreviewImageBase64(base64) {
            this.distoredImage = new Image();
            this.distoredImage.src = base64;

            this.distoredImage.onload = () => {
                // Source image is scaled to output size, so we need to scale it back to the original size
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                if (this.isPortrait) {
                    canvas.width = this.designerVariant.output_image.height / this.designerVariant.scale_factor.height;
                    canvas.height = this.designerVariant.output_image.width / this.designerVariant.scale_factor.width;
                } else {
                    canvas.width = this.designerVariant.output_image.width / this.designerVariant.scale_factor.width;
                    canvas.height = this.designerVariant.output_image.height / this.designerVariant.scale_factor.height;
                }

                // Draw the image only after it has loaded
                ctx.drawImage(this.distoredImage, 0, 0, canvas.width, canvas.height);

                // Convert canvas to base64 and update preview image
                this.previewImage = new Image();
                this.previewImage.src = canvas.toDataURL("image/png");
                this.updatePreviewImage();
            };
        },

        getDistoredImage() {
            return this.distoredImage;
        },

        getDistortedImageBase64() {
            return this.distoredImage.src;
        },

        getPreviewImage() {
            return this.previewImage;
        },

        getPreviewImageBase64() {
            return this.previewImage.src;
        },

        updatePreviewImage() {
            this.$refs.image.src = this.getPreviewImageBase64();
        },

        async initControl(base64Image, isPortrait = false) {
            this.isLoading = true;
            this.isPortrait = isPortrait;

            // Set image
            this.originalImage = new Image();
            this.setPreviewImageBase64(base64Image);
            this.originalImage.onload = () => {
                this.isLoading = false;
                this.invalidateImage();
            };

            this.originalImage.src = base64Image;

            // Get designer variant
            const colorCount = this.wpAttributes.colorCount;
            this.designerVariant = this.config.product_variants.find(
                (variant) => Number(variant.colors) === Number(colorCount)
            );

            this.setDefaultValues();
        },

        async invalidateImage() {
            if (this.getOriginalImage === null) {
                console.error("Image not loaded");
                return;
            }

            this.isProcessing = true;
            this.checkFilterSettings();

            // Image manipulation without worker
            const brightnessValue = (2 * this.brightness) / (this.brightnessMax - this.brightnessMin) + 1;
            const contrastValue = (2 * this.contrast) / (this.contrastMax - this.contrastMin) + 1;
            const saturationValue = (2 * this.saturation) / (this.saturationMax - this.saturationMin) + 1;
            const hueValue = ((2 * this.hue) / (this.hueMax - this.hueMin)) * 180;
            const invertValue = this.invert ? 1 : 0;

            var image = this.getOriginalImage();
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = image.width;
            canvas.height = image.height;
            ctx.filter = `invert(${invertValue}) hue-rotate(${hueValue}deg) brightness(${brightnessValue}) contrast(${contrastValue}) saturate(${saturationValue}) `;
            ctx.drawImage(image, 0, 0);
            const { data, width, height } = ctx.getImageData(0, 0, image.width, image.height);

            // Prepare data for the worker
            const colors = this.selectedColors.map((color) => color.hex);

            // Convert data to a Uint8Array if necessary
            const imageData = Uint8Array.from(data);

            // Send the data to the worker
            const workerData = {
                colors: colors,
                ditheringAlgorithm: this.ditheringAlgorithm,
                image: {
                    data: imageData,
                    width,
                    height,
                },
            };

            if (this.disableQuantisation) {
                this.handleOnImageProcessed(workerData.image);
                return;
            }

            if (this.imageWorker) {
                this.imageWorker.enqueueMessage(workerData);
                //this.handleOnImageProcessed(await this.imageWorker.process(workerData));
            } else {
                console.error("Image worker not available");
            }
        },

        // Callbacks
        handleOnColorChange(id, value) {
            // get color for selected value
            this.selectedColors[id] = this.config.colors.find((c) => c.hex.toLowerCase() === value.toLowerCase());
            this.invalidateImage();
        },

        handleOnImageProcessed(bitmap) {
            const { width, height, data } = bitmap;
            const image = new ImageData(new Uint8ClampedArray(data), width, height);
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.putImageData(image, 0, 0);
            this.setPreviewImageBase64(canvas.toDataURL("image/png"));
            this.isProcessing = false;
        },

        // Helper functions
        checkFilterSettings() {
            if (this.brightness < this.brightnessMin) this.brightness = this.brightnessMin;
            if (this.brightness > this.brightnessMax) this.brightness = this.brightnessMax;

            if (this.saturation < this.saturationMin) this.saturation = this.saturationMin;
            if (this.saturation > this.saturationMax) this.saturation = this.saturationMax;

            if (this.blur < this.blurMin) this.blur = this.blurMin;
            if (this.blur > this.blurMax) this.blur = this.blurMax;

            if (this.contrast < this.contrastMin) this.contrast = this.contrastMin;
            if (this.contrast > this.contrastMax) this.contrast = this.contrastMax;

            if (this.hue < this.hueMin) this.hue = this.hueMin;
            if (this.hue > this.hueMax) this.hue = this.hueMax;
        },

        setDefaultValues() {
            const defaultConfig = this.designerVariant.designer_preselection;
            this.brightness = defaultConfig.default_brightness;
            this.saturation = defaultConfig.default_saturation;
            this.contrast = defaultConfig.default_contrast;
            this.hue = defaultConfig.default_hue;
            this.invert = defaultConfig.default_invert;
            this.ditheringAlgorithm = defaultConfig.default_dithering_algorithm;

            // Extract default colors from designer variant
            const defaultColors = [];
            defaultConfig.default_colors.forEach((colorId) => {
                // search color by id
                const color = this.config.colors.find((c) => Number(c.id) === Number(colorId));
                if (color) {
                    defaultColors.push(color);
                } else {
                    console.warn("Invalid color configured with id: " + colorId);
                }
            });

            // Set selected colors
            for (let i = 0; i < defaultColors.length; i++) {
                this.selectedColors[i] = defaultColors[i];
                this.$refs.colorSelects[i].selectColor(defaultColors[i], true);
            }
        },
    },
};
</script>

<style>
.ec-edit-control-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
}

.ec-image-container {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ec-image-wrapper{
    max-height: 50vh;
    max-width: 100%;
    position: relative;
}

.edit-control-image {
    max-height: 50vh;
    max-width: 100%;
    object-fit: contain;
}

.ec-control-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    flex-grow: 1;
    margin: 1.5em;
    display: inline-block;
}

.color-select-control-title,
.checkbox-control-title,
.button-control-title,
.slider-control-title {
    width: 100px;
}

/* Overlay that covers the entire image when processing */
.edit-control-overlay {
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Simple spinner styling */
.edit-control-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spin 2s linear infinite;
    margin-bottom: 8px;
}

/* Spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>