<template>
    <div class="button-control">
        <div class="button-control-title">{{ title }}:</div>

        <button class="button-control-input" v-on:click="$emit('onClick')">{{ text }}</button>
    </div>
</template>

<script>
export default {
    name: "ButtonControl",
    props: {
        title: {
            type: String,
            default: "Value",
        },
        text: {
            type: String,
            default: "Click me",
        },
    },
};
</script>

<style scoped>
.button-control {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.button-control-title {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.button-control-input button {
    padding: 0px 10px;
    cursor: pointer;
}
</style>
