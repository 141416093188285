<template>
    <div class="slider-control">
        <div class="slider-control-title">{{ title }}:</div>

        <input
            class="slider-control-slider"
            type="range"
            :min="min"
            :max="max"
            v-model="internalValue"
            @change="handleChange"
        />

        <input
            class="slider-control-textbox"
            type="number"
            :min="min"
            :max="max"
            v-model="internalValue"
            @change="handleChange"
            @keyup="handleKeyup"
        />

        <button class="slider-control-button" @click="onReset">Reset</button>
    </div>
</template>

<script>
export default {
    name: "SliderControl",
    props: {
        min: {
            type: Number,
            default: -100,
        },
        max: {
            type: Number,
            default: 100,
        },
        defaultValue: {
            type: Number,
            default: 0,
        },
        title: {
            type: String,
            default: "Value",
        },
        modelValue: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            internalValue: this.modelValue,
        };
    },
    watch: {
        modelValue(newVal) {
            this.internalValue = newVal;
        },
        internalValue(newVal) {
            // Removed automatic emission on internalValue change
            // to control when to emit based on specific events
        },
    },
    mounted() {
        // Initialize internalValue with defaultValue on mount
        this.internalValue = this.defaultValue;
    },
    methods: {
        handleChange() {
            // Emit update:modelValue when the slider is released or input loses focus
            this.$emit("update:modelValue", this.internalValue);
            this.$emit("onValueChanged", this.internalValue);
        },
        handleKeyup() {
            // Emit update:modelValue on keyup in the number input
            this.$emit("update:modelValue", this.internalValue);
            this.$emit("onValueChanged", this.internalValue);
        },
        onReset() {
            this.internalValue = this.defaultValue;
            this.$emit("update:modelValue", this.internalValue);
            this.$emit("onValueReset", this.internalValue);
        },
    },
};
</script>

<style scoped>
.slider-control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: baseline;
    justify-content: space-between;
    margin: 0.5em 0;
    gap: 0.5em;
}

.slider-control-title {
    flex-grow: 0;
    min-width: 100px;
}

.slider-control-slider {
    flex-grow: 6;
    min-width: 10vw;
}

.slider-control-textbox {
    flex-grow: 0;
    min-width: 5vw;
}

.slider-control-button {
    flex-grow: 0;
    cursor: pointer;
    min-width: 5vw;
}


</style>
