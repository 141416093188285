import * as Utils from "./utils.js";
import MyWorker from 'worker-loader?inline=no-fallback!./image-worker.js';

export class ImageProcessor {
    worker = null;
    workerDataQueue = [];
    isBusy = false;
    callback = null;
    workerPath = null;

    constructor(workerPath) {
        // Create a new worker
        this.workerPath = workerPath;
        this.isBusy = false;
    }

    async process(message) {
        const {
            colors,
            ditheringAlgorithm,
            image: {
                data,
                width,
                height,
            },
        } = message;
        const postData = await Utils.reduceColors({ data, width, height }, colors, ditheringAlgorithm);
        return postData;
    }


    createWorker() {
        this.worker = new MyWorker();
        this.isBusy = false;
        this.worker.onmessage = (event) => {
            this.onWorkerMessage(event.data);
        };
    }

    enqueueMessage(data) {
        this.workerDataQueue.push(data);
        this.evaluateQueue();
    }

    evaluateQueue() {
        if (!this.isBusy && this.workerDataQueue.length > 0) {
            this.isBusy = true;
            const taskData = structuredClone(this.workerDataQueue[this.workerDataQueue.length - 1]);
            this.workerDataQueue = [];

            this.worker.postMessage(taskData);
        }
    }

    onWorkerMessage(data) {
        this.isBusy = false;
        this.evaluateQueue();

        if (this.callback && !this.isBusy) {
            this.callback(data);
        }

    }

    registerOnMessageCallback(callback) {
        this.callback = callback;
    }

    unregisterOnMessageCallback() {
        this.callback = null;
    }
}

export default ImageProcessor;