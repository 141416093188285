<template>
    <div class="checkbox-control">
        <div class="checkbox-control-title">{{ title }}:</div>
        <div class="checkbox-control-input">
            <input type="checkbox" v-model="internalValue" @change="handleChange" />
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckboxControl", // Updated to PascalCase
    props: {
        title: {
            type: String,
            default: "Value",
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            internalValue: this.modelValue,
        };
    },
    watch: {
        modelValue(newVal) {
            this.internalValue = newVal;
        },
    },
    methods: {
        handleChange() {
            // Emit update:modelValue when the checkbox state changes
            this.$emit("update:modelValue", this.internalValue);
            this.$emit("onValueChanged", this.internalValue);
        },

        setChecked(value) {
            this.internalValue = value;
        },
    },
};
</script>

<style scoped>
.checkbox-control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: baseline;
    justify-content: space-between;
}

.checkbox-control-title {
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex-grow: 0;
    width: 100px;
}

.checkbox-control-input {
    transform: scale(1.5);
    transform-origin: center left;
    cursor: pointer;
    flex-grow: 8;
}
</style>
