<template>
    <div class="color-select-control" @click.stop>
        <div class="color-select-control-title">{{ title }}:</div>

        <div class="color-select-control-display" @click="toggleDropdown">
            <span
                v-if="selectedColorObject"
                class="color-square"
                :style="{ backgroundColor: selectedColorObject.hex }"
            ></span>
            <span class="color-name">
                {{ selectedColorObject ? selectedColorObject.name : defaultText }}
            </span>
            <span class="dropdown-arrow" :class="{ open: isOpen }">▼</span>
        </div>

        <!-- Dropdown Options -->
        <transition name="fade">
            <ul v-if="isOpen" class="dropdown-options">
                <li v-for="(item, index) in colors" :key="index" class="dropdown-option" @click="selectColor(item)">
                    <span class="color-square" :style="{ backgroundColor: item.hex }"></span>
                    <span class="color-name">{{ item.name }}</span>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>

export default {
    name: "ColorSelectControl",
    props: {
        modelId: {
            type: Number,
            default: 0,
        },
        title: {
            type: String,
            default: "Select Color",
        },

        modelValue: {
            type: String,
            default: "",
        },

        defaultText: {
            type: String,
            default: "Farbe auswählen",
        },

        /**
         * Array of color options, e.g.:
         * [
         *   { name: "Red", hex: "#FF0000" },
         *   { name: "Green", hex: "#00FF00" },
         *   { name: "Blue", hex: "#0000FF" },
         * ]
         */
        colors: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isOpen: false,
            internalValue: this.modelValue,
        };
    },
    computed: {
        selectedColorObject() {
            const x =  this.colors.find((c) => c.hex.toLowerCase() === this.internalValue.toLowerCase()) || null;
            return x;
        },
    },
    watch: {
        modelValue(newVal) {
            this.internalValue = newVal;
        },

        internalValue(newVal) {
            this.$emit("update:modelValue", newVal);
        },
    },

    mounted() {
        document.addEventListener("click", this.handleOutsideClick);
    },

    beforeUnmount() {
        document.removeEventListener("click", this.handleOutsideClick);
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },

        selectColor(colorItem, silent = false) {
            this.internalValue = colorItem.hex;
            if (!silent) {
                this.$emit("onValueChanged", this.modelId, this.internalValue);
            }
            this.isOpen = false;
        },

        handleOutsideClick() {
            this.isOpen = false;
        },
    },
};
</script>

<style scoped>
.color-select-control {
    display: flex;
    align-items: baseline;
    gap: 1em;
    position: relative;
    user-select: none;
    margin: 10px 0;
}

.color-select-control-title {
    margin: 0;
    width: 100px;
}

.color-select-control-display {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 6px 8px;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.color-square {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    border: 1px solid #ccc;
}

.color-name {
    color: #000;
    margin-right: 8px;
}

.dropdown-arrow {
    margin-left: auto;
    font-size: 12px;
    transition: transform 0.2s ease;
}

.dropdown-arrow.open {
    transform: rotate(180deg);
}

/* Dropdown Options */
.dropdown-options {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 999;
    background-color: #fff;
    list-style-type: none;
    margin: 0;
    padding: 4px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.dropdown-option {
    padding: 6px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: #f5f5f5;
}

.dropdown-option .color-square {
    margin-right: 6px;
}

/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
