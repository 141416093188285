import { createApp, Vue } from 'vue'
import App from './App.vue'
import metadata from './wp-attributes.json'
import config from './config.json'

const appContainerList = document.querySelectorAll('#app');

// Select all instances of divs with id "app"
appContainerList.forEach((appContainer) => {
    let wpAttributes = null;

    // Parse attributes from the data-attributes attribute
    try {
        wpAttributes = JSON.parse(appContainer.dataset.attributes);
    } catch (e) {
        console.warn('Error parsing WordPress attributes, using default values');
    }

    if (!wpAttributes) {
        wpAttributes = metadata;
    }

    // Check required attributes
    if (!("addToCartUrl" in wpAttributes)) {
        console.error('addToCartUrl is missing from the attributes');
    }

    if (!("colorCount" in wpAttributes)) {
        console.error('colorCount is missing from the attributes');
    }

    if (!("productId" in wpAttributes)) {
        console.error('productId is missing from the attributes');
    }

    // Create a new Vue instance for each container
    const app = createApp(App);

    // Provide attributes as global properties
    app.provide('wpAttributes', wpAttributes);
    app.provide('config', config);

    app.mount(appContainer);
});


// == Single instance of Vue app ==
// const appContainer = document.getElementById('app');

// // Parse attributes from the data-attributes attribute
// var wpAttributes = null;

// try {
//     wpAttributes = JSON.parse(appContainer.dataset.attributes);
// }
// catch (e) {
//     console.warn('Error parsing wordpress attributes, using default values');
// }

// if (wpAttributes === undefined || wpAttributes === null) {
//     wpAttributes = metadata;
// }

// if (!("addToCartUrl" in wpAttributes)) {
//     console.error('addToCartUrl is missing from the attributes');
// }

// if (!("colorCount" in wpAttributes)) {
//     console.error('colorCount is missing from the attributes');
// }

// if (!("productId" in wpAttributes)) {
//     console.error('productId is missing from the attributes');
// }

// // Create the Vue app and pass the attributes as a global property
// const app = createApp(App);

// // Make attributes globally available throughout the app
// app.provide('wpAttributes', wpAttributes);
// app.provide('config', config);


// app.mount('#app')
