<template>
    <transition name="fade">
        <div class="modal-background" v-show="open">
            <div ref="modal" class="modal" v-show="open">
                <div class="modal-content">
                    <div class="modal-title-bar">
                        <div class="modal-title">
                            {{ title }}
                        </div>
                        <div class="modal-close">
                            <button class="button-close" type="button" @click="$emit('close')">X</button>
                        </div>
                    </div>
                    <slot />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Modal",
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    methods: {
        handleModalSize() {
            this.$nextTick(() => {
                if (this.open && this.$refs.modal) {
                    const el = this.$refs.modal;
                    const width = el.offsetWidth;
                    const height = el.offsetHeight;
                    this.$emit("resize", { width, height });
                }
            });
        },
    },
    watch: {
        open(newVal) {
            if (newVal) {
                this.handleModalSize();
            }
        },
    },
    mounted() {
        if (this.open) {
            this.handleModalSize();
        }

        window.addEventListener("resize", this.handleModalSize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleModalSize);
    },
};
</script>

<style>

/* *,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99999 !important;
}

.modal {
    width : 95%;
    height : 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #fff; */
}

.modal-title-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 1em;
    padding: 0.5em;
    border-bottom: 1px solid #000;
}

.modal-title {
    text-align: left;
    font-size: 1.5em;
    flex-grow: 1;
}

.modal-content {
    /* position: relative; */
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.3rem;
    padding: 1.5rem;
    width: 100%;
    box-sizing: border-box;
}


.modal-close {
    flex-shrink: 0;
}

.button-close {
    background: #000;
    color: #fff;
    border: none;
    padding: 0.25em;
    width: 2em;
    height: 2em;
    border-radius: 0.3em;
}

/* animations: */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>