import { Jimp } from "jimp";
import * as IQ from "image-q";
import { Buffer } from "buffer";

/**
 * Convert a hex color string (e.g. "#RRGGBB") to [r, g, b].
 * @param {string} hex - The hex color string.
 * @returns {number[]} The RGB color as an array.
 * @example
 * hexToRgb("#ff0000"); // [255, 0, 0]
 */
export function hexToRgb(hex) {
  const normalized = hex.replace(/^#/, "");
  const intVal = parseInt(normalized, 16);
  const r = (intVal >> 16) & 255;
  const g = (intVal >> 8) & 255;
  const b = intVal & 255;
  return [r, g, b];
}

/**
 * Convert an RGB color to a hex color string.
 * @param {number[r, g, b]} rgb - The RGB color as an array.
 * @returns {string} The hex color string.
 */
export function rgbToHex(rgb) {
  return `#${((rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).padStart(6, "0")}`;
}

/**
 * Convert an RGB color to a hex color string.
 * @param {number} r - The red value.
 * @param {number} g - The green value.
 * @param {number} b - The blue value.
 * @returns {string} The hex color string.
 */
export function rgbToHex2(r, g, b) {
  return `#${((r << 16) + (g << 8) + b).toString(16).padStart(6, "0")}`;
}

/**
 * Converts RGB to HSV.
 *
 * @param {number[r, g, b]} rgb - An array containing red, green, and blue components (0-255).
 * @returns {Object} An object containing hue (0-360), saturation (0-100), and value (0-100).
 */
export function rgbToHsv(rgb) {
  return rgbToHsv2(rgb[0], rgb[1], rgb[2]);
}

/**
 * Converts RGB to HSV.
 *
 * @param {number} r - Red component (0-255).
 * @param {number} g - Green component (0-255).
 * @param {number} b - Blue component (0-255).
 * @returns {Object} An object containing hue (0-360), saturation (0-100), and value (0-100).
 */
export function rgbToHsv2(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h, s, v = max;

  const delta = max - min;

  // Hue calculation
  if (delta === 0) {
    h = 0;
  } else if (max === r) {
    h = 60 * (((g - b) / delta) % 6);
  } else if (max === g) {
    h = 60 * (((b - r) / delta) + 2);
  } else { // max === b
    h = 60 * (((r - g) / delta) + 4);
  }

  if (h < 0) h += 360;

  // Saturation calculation
  s = max === 0 ? 0 : (delta / max) * 100;

  // Value calculation
  v = v * 100;

  return { h, s, v };
}

/**
 * Converts HSV color values to RGB.
 *
 * @param {number[h, s, v]} hsv - An object containing hue (0-360), saturation (0-100), and value (0-100).
 * @returns {Object} An object containing R, G, and B components (0-255).
 */
function hsvToRgb(hsv) {
  return hsvToRgb2(hsv[0], hsv[1], hsv[2]);
}

/**
 * Converts HSV color values to RGB.
 *
 * @param {number} h - Hue component (0-360 degrees).
 * @param {number} s - Saturation component (0-100 percent).
 * @param {number} v - Value component (0-100 percent).
 * @returns {Object} An object containing R, G, and B components (0-255).
 */
function hsvToRgb2(h, s, v) {
  // Ensure hue is within [0, 360)
  h = h % 360;
  if (h < 0) h += 360;

  // Convert saturation and value to [0,1]
  s = s / 100;
  v = v / 100;

  const c = v * s; // Chroma
  const hPrime = h / 60;
  const x = c * (1 - Math.abs((hPrime % 2) - 1));
  let r1, g1, b1;

  if (0 <= hPrime && hPrime < 1) {
    [r1, g1, b1] = [c, x, 0];
  } else if (1 <= hPrime && hPrime < 2) {
    [r1, g1, b1] = [x, c, 0];
  } else if (2 <= hPrime && hPrime < 3) {
    [r1, g1, b1] = [0, c, x];
  } else if (3 <= hPrime && hPrime < 4) {
    [r1, g1, b1] = [0, x, c];
  } else if (4 <= hPrime && hPrime < 5) {
    [r1, g1, b1] = [x, 0, c];
  } else if (5 <= hPrime && hPrime < 6) {
    [r1, g1, b1] = [c, 0, x];
  } else {
    [r1, g1, b1] = [0, 0, 0];
  }

  const m = v - c;
  const r = Math.round((r1 + m) * 255);
  const g = Math.round((g1 + m) * 255);
  const b = Math.round((b1 + m) * 255);

  return { R: r, G: g, B: b };
}

/**
 * Generates a hue-based histogram for an image.
 *
 * @param {Jimp} jimpImage - The Jimp image object.
 * @param {number} binCount - Number of bins for the histogram (default: 32).
 * @returns {Array} An array representing the histogram frequencies based on hue.
 */
export function histogram(jimpImage, binCount = 32) {
  const width = jimpImage.bitmap.width;
  const height = jimpImage.bitmap.height;
  const totalPixels = width * height;

  // Initialize a single histogram
  const histogram = new Array(binCount).fill(0);

  // Define the size of each bin in degrees
  const binSize = 360 / binCount;

  // Iterate through each pixel to populate the histogram
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const rgba = Jimp.intToRGBA(jimpImage.getPixelColor(x, y));

      // Convert RGB to HSV
      const hsv = rgbToHsv2(rgba.r, rgba.g, rgba.b);

      // Extract hue
      const hue = hsv.h;

      // Calculate bin index
      const bin = Math.min(Math.floor(hue / binSize), binCount - 1);

      // Increment the corresponding bin
      histogram[bin]++;
    }
  }

  return histogram;
}

/**
 * Get the center colors of the bins in a hue-based histogram.
 * @param {number} binCount - Number of bins for the histogram (default: 32).
 */
export function histogramCenters(binCount = 32) {
  const binSize = 360 / binCount;
  return Array.from({ length: binCount }, (_, i) => Math.round((i + 0.5) * binSize) % 360);
}

/**
 * Reduce the number of colors to a given list of colors using Image Quantization.
 * @param {data,width,height} bitmap - The input image data, width, and height.
 * @param {String[]} hexColorPalette - The list of colors to reduce the image to.
 * @returns {data,width,height} The reduced image data, width, and height.
 */
export async function reduceColors(bitmap, hexColorPalette, ditheringMethod = "floyd-steinberg") {

  // Convert hex colors to RGB
  const rgbColorPalette = hexColorPalette.map(hexToRgb);

  // Extract width, height, and RGBA data
  const { width, height, data } = bitmap;
  // data is a Buffer in Node, so convert it to a Uint8Array if needed:
  const rgbaData = new Uint8Array(data.buffer);

  // Create a PointContainer for image-q
  const pointContainer = IQ.utils.PointContainer.fromUint8Array(rgbaData, width, height);

  const palette = new IQ.utils.Palette();
  rgbColorPalette.forEach((rgbColor) => {
    const colorPoint = IQ.utils.Point.createByRGBA(rgbColor[0], rgbColor[1], rgbColor[2], 255);
    palette.add(colorPoint);
  });

  // Apply the palette to the image with dithering
  const reducedPointContainer = IQ.applyPaletteSync(pointContainer, palette, {
    colorDistanceFormula: "euclidean", // Optional
    imageQuantization: ditheringMethod, // Dithering
  });

  // Convert reduced data back to RGBA
  const reducedImageData = reducedPointContainer.toUint8Array();


  return {
    width,
    height,
    data: Buffer.from(reducedImageData),
  };

}
