<template>
    <div class="map-control-content">
        <div class="map-container" ref="viewmapcontainer">
            <div class="map-wrapper" ref="viewmap">
                <div class="map" ref="map"></div>
                <div v-if="isLoading" class="map-overlay">
                    <div class="map-spinner"></div>
                </div>
            </div>
        </div>
        <div class="info-container">
            <h3>Wählen Sie den gewünschten Kartenausschnitt</h3>
            <br />
            <div>
                <p>
                    Klicken und ziehen Sie, um den Kartenausschnitt zu verschieben. Verwenden Sie das Mausrad, um die
                    Zoomstufe zu ändern.
                </p>
                <div class="suggestions" style="padding-top: 0.5em">
                    <button class="suggestion-button" @click="setPortait(false)">Querformat</button>
                    <button class="suggestion-button" @click="setPortait(true)">Hochformat</button>
                </div>
            </div>
            <br />
            <div>
                <p>
                    Klicken Sie auf einen Vorschlag unten, um den Kartenausschnitt zu zentrieren. Klicken Sie auf
                    "Zufall", um einen zufälligen Kartenausschnitt zu wählen.
                </p>
                <div class="suggestions" style="padding-top: 0.5em">
                    <button
                        v-for="suggestion in config.suggestions"
                        :key="suggestion.id"
                        @click="centerMap(suggestion.location)"
                        class="suggestion-button"
                    >
                        {{ suggestion.name }}
                    </button>
                    <button @click="randomCenterMap" class="suggestion-button">Zufall</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from "vue";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import leafletImage from "leaflet-image";

export default {
    name: "Map",

    setup() {
        // Inject the configuration and WordPress attributes
        const config = inject("config");
        const wpAttributes = inject("wpAttributes");
        return { config, wpAttributes };
    },

    data() {
        return {
            isLoading: false,
            center: [47.49247, 8.252113],
            zoom: 15,
            minZoom: 7,
            maxZoom: 19,
            baseUrl: "https://wmts.geo.admin.ch/1.0.0/{layername}/default/{timestamp}/3857/{z}/{x}/{y}.jpeg",
            attribution: "&copy; <a href='https://www.swisstopo.admin.ch'>swisstopo</a>",
            layer: "ch.swisstopo.pixelkarte-farbe",
            timestamp: "current",
            map: null,
            designerVariant: null,
            isPortrait: false,
            windowWidth: 0,
            windowHeight: 0,
            modalWidth: 0,
            modalHeight: 0,
        };
    },

    methods: {
        // Generate the WMTS URL with the layer name and timestamp
        generateWmtsUrl() {
            return this.baseUrl.replace("{layername}", this.layer).replace("{timestamp}", this.timestamp);
        },

        // Initialize the map with the center, zoom, minZoom, maxZoom, and attribution
        initializeMap() {
            const mapDiv = this.$refs.map;
            mapDiv.id = "map-" + Math.random().toString(36).slice(2, 11);
            this.map = L.map(mapDiv.id).setView(this.center, this.zoom);
            L.tileLayer(this.generateWmtsUrl(), {
                maxZoom: this.maxZoom,
                minZoom: this.minZoom,
                attribution: this.attribution,
                crossOrigin: "anonymous",
            }).addTo(this.map);

            const resizeObserver = new ResizeObserver(() => {
                this.map.invalidateSize();
                this.invalidateView();
            });

            resizeObserver.observe(mapDiv);
        },

        // Get the current map image as a base64 data URL
        getCurrentMapImage(onCompleted) {
            this.isLoading = true;
            leafletImage(this.map, (err, canvas) => {
                // scaled size is downloaded. Processing needs to be in output size so resize the image to the output size (distorted)
                // in later views, the image will be resized to the correct size for viewing (data source is the output size)

                // Resize image to the desired output size
                let output_width = 0;
                let output_height = 0;
                if (this.isPortrait) {
                    output_width = this.designerVariant.output_image.height;
                    output_height = this.designerVariant.output_image.width;
                } else {
                    output_width = this.designerVariant.output_image.width;
                    output_height = this.designerVariant.output_image.height;
                }

                // Create a temporary canvas for resizing
                const tempCanvas = document.createElement("canvas");
                const ctx = tempCanvas.getContext("2d");

                // Set the canvas size to the desired output dimensions
                tempCanvas.width = output_width;
                tempCanvas.height = output_height;

                // Draw the existing image onto the resized canvas
                ctx.drawImage(canvas, 0, 0, output_width, output_height);

                onCompleted(tempCanvas.toDataURL());
                this.isLoading = false;
            });
        },

        // Center the map to the specified location
        centerMap(location) {
            const latlng = [location.lat, location.lng];
            const zoom = location.zoom;
            console.log("Centering map to", latlng, zoom);
            this.map.setView(latlng, zoom);
        },

        // Center the map to a random location an zoom level
        randomCenterMap() {
            const minLat = this.config.random_suggestion.lat[0];
            const maxLat = this.config.random_suggestion.lat[1];
            const minLng = this.config.random_suggestion.lng[0];
            const maxLng = this.config.random_suggestion.lng[1];
            const minZoom = this.config.random_suggestion.zoom[0];
            const maxZoom = this.config.random_suggestion.zoom[1];

            const lat = Math.random() * (maxLat - minLat) + minLat;
            const lng = Math.random() * (maxLng - minLng) + minLng;
            const zoom = Math.floor(Math.random() * (maxZoom - minZoom + 1)) + minZoom;

            this.centerMap({ lat, lng, zoom });
        },

        // Set the orientation of the map (portrait or landscape)
        setPortait(isPortrait) {
            this.isPortrait = isPortrait;
            this.invalidateView();
        },

        // Recalulate the map size and scale to fit the container
        invalidateView() {
            // Calculate optimal scale factor to fit the map in the container

            // Get available space for map
            const containerWidth = this.$refs.viewmapcontainer.offsetWidth;

            // Calculate the needed image size
            const sizeX = Math.round(this.designerVariant.output_image.width / this.designerVariant.scale_factor.width);
            const sizeY = Math.round(this.designerVariant.output_image.height / this.designerVariant.scale_factor.height);
            let mapWidth = 0;
            let mapHeight = 0;
            if (this.isPortrait) {
                mapWidth = sizeY;
                mapHeight = sizeX;
            } else {
                mapWidth = sizeX;
                mapHeight = sizeY;
            }

            // containerWidth is a hard limit, mapWidth must not exceed containerWidth
            // containerHeight is a soft limit, mapHeight might exceed containerHeight
            // 70% of the windowHeight is a hard limit, mapHeight must not exceed 70% of the windowHeight
            let maxHeight = this.windowHeight * 0.7;

            // Calculate the scale factor to fit the map in the container
            const widthScaleFactor = containerWidth / mapWidth;
            const heightScaleFactor = maxHeight / mapHeight;
            const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

            // Set the map size and scale
            this.$refs.map.style.width = mapWidth + "px";
            this.$refs.map.style.height = mapHeight + "px";
            this.$refs.viewmap.style.width = mapWidth * scaleFactor + "px";
            this.$refs.viewmap.style.height = mapHeight * scaleFactor + "px";

            // Apply the scale factor to the map
            this.$refs.map.style.transform = `scale(${scaleFactor})`;
        },

        // Update the window size (called on resize)
        updateWindowSize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            this.invalidateView();
        },
    },

    mounted() {
        // Update the map configuration from the designer settings
        const designerConfig = this.config.designer;
        this.center = [designerConfig.map_center.lat, designerConfig.map_center.lng];
        this.zoom = designerConfig.map_zoom;
        this.minZoom = designerConfig.map_min_zoom;
        this.maxZoom = designerConfig.map_max_zoom;
        this.baseUrl = designerConfig.map_base_url;
        this.attribution = designerConfig.map_attribution;
        this.layer = designerConfig.map_layer;
        this.timestamp = designerConfig.map_timestamp;

        // Get the color count from the WordPress attributes
        const wpAttributes = this.wpAttributes;
        const colorCount = wpAttributes.colorCount;

        // Set the map size and scale
        this.config.product_variants.forEach((variant) => {
            if (variant.colors === colorCount) {
                this.designerVariant = variant;
            }
        });

        // Add event listener for window resize
        window.addEventListener("resize", this.updateWindowSize);

        // Initialize the map
        this.updateWindowSize(); // Needed to set the initial size of the window
        this.invalidateView(); // Resize the map to fit the container
        this.initializeMap(); // Initialize the map
        this.map.invalidateSize(); // Fix for map not rendering properly
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.updateWindowSize);
    },
};
</script>

<style>
.suggestions {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.suggestion-button {
    padding: 0.5em 1em;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(17, 17, 17);
    border-color: rgb(17, 17, 17);
    color: white;
}

.map-control-content {
    /* border: 1px solid #f00; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
}

.info-container {
    margin: 1em;
    flex: 1 1 0;
    min-width: 20vw;
    white-space: normal;
    overflow-wrap: break-word;
}

.map-container {
    /* border: 1px solid #00f; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 0;
    min-width: 60vw;
    max-height: 70vh;
}

.map-wrapper {
    /* border: 1px solid #0f0; */
    flex: 0 0 auto;
    position: relative;
    display: inline-block;
}

/* Map styling, width and height are set dynamically */
.map {
    width: 1500;
    height: 1000px;
    transform-origin: top left;
}

/* Overlay that covers the entire image when processing */
.map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    /* Match the width/height to the .image-wrapper / img dimensions */
    width: 100%;
    height: 100%;
    /* Light overlay effect */
    background: rgba(255, 255, 255, 0.85);
    /* Flex layout to center spinner and text */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Simple spinner styling */
.map-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spin 2s linear infinite;
    margin-bottom: 8px;
}

/* Spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>