<template>
    <button @click="openModal" class="wp-element-button wc-block-components-product-button__button add_to_cart_button">
        {{ buttonText }}
    </button>

    <Modal :open="isOpen" title="Topodecki Designer" @close="isOpen = false">
        <form-wizard
            ref="wizard"
            @on-complete="onComplete"
            shape="tab"
            color="#111111"
            nextButtonText="Weiter"
            backButtonText="Zurück"
            finishButtonText="In den Warenkorb"
        >
            <!-- Select desired map area -->
            <tab-content class="tab-content" title=" Kartenausschnitt" :before-change="showEditor">
                <Map ref="mapview" />
            </tab-content>

            <!-- Edit the selected map area -->
            <tab-content title="Bearbeiten" :before-change="showPreview">
                <EditControl ref="editControl" />
            </tab-content>

            <!-- Preview the edited map area -->
            <tab-content title="Vorschau">
                <PreviewControl ref="previewControl" />
            </tab-content>
        </form-wizard>
    </Modal>
</template>

<script>
import { inject, ref } from "vue";
import Modal from "./components/Modal.vue";
import SliderControl from "./components/SliderControl.vue";
import Map from "./components/Map.vue";
import EditControl from "./components/EditControl.vue";
import PreviewControl from "./components/PreviewControl.vue";
import CheckboxControl from "./components/CheckboxControl.vue";
import ColorSelectControl from "./components/ColorSelectControl.vue";
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";

export default {
    name: "App",
    components: {
        Map,
        Modal,
        SliderControl,
        CheckboxControl,
        ColorSelectControl,
        FormWizard,
        TabContent,
        EditControl,
        PreviewControl,
    },
    setup() {
        const wpAttributes = inject("wpAttributes");
        const config = inject("config");
        const isOpen = ref(wpAttributes.autoShow);
        const text = ref(wpAttributes.text);
        return { wpAttributes, config, isOpen, text };
    },
    data() {
        return {
            text: "Topodecki Designer",
        };
    },
    computed: {
        buttonText() {
            if (this.wpAttributes.text) {
                return this.wpAttributes.text;
            } else {
                return this.config.designer.default_text;
            }
        },
    },

    methods: {
        openModal() {
            this.$refs.wizard.reset();
            this.isOpen = true;
        },

        async showEditor() {
            await this.renderImage();
            return true;
        },

        showPreview() {
            const img = this.$refs.editControl.getDistortedImageBase64();
            const isPortrait = this.$refs.mapview.isPortrait;
            this.$refs.previewControl.initControl(img, isPortrait);

            return true;
        },

        // Callbacks
        onComplete() {
            this.$refs.previewControl.addToCart();
        },

        async renderImage() {
            // Use a Promise to handle the async operation
            await new Promise((resolve, reject) => {
                // Callback function to get the current map image
                const isPortrait = this.$refs.mapview.isPortrait;
                this.$refs.mapview.getCurrentMapImage(async (imageData) => {
                    if (imageData) {
                        // Directly set the image source here
                        await this.$refs.editControl.initControl(imageData, isPortrait);
                        resolve();
                    } else {
                        reject("Image rendering failed");
                    }
                });
            });

            return true;
        },
    },
};
</script>

<style>
</style>


