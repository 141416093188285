<template>
    <div class="pc-preview-control-content">
        <div class="pc-image-container" ref="imageview">
            <VueZoomable class="pc-image-wrapper" selector="#pc-image" :minZoom="0.1" :maxZoom="10" :zoom="1">
                <img id="pc-image" class="pi-image" ref="image" draggable="false" />
            </VueZoomable>
        </div>

        <div class="pc-info-container" ref="controlview">
            <div ref="info">
                <div class="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                    <h1 class="wp-block-post-title">{{ this.wpAttributes.productTitle }}</h1>

                    <div
                        data-block-name="woocommerce/product-price"
                        data-font-size="large"
                        data-is-descendent-of-single-product-template="true"
                        class="wp-block-woocommerce-product-price"
                    >
                        <div
                            class="wc-block-components-product-price wc-block-grid__product-price has-text-align- has-font-size has-large-font-size has-text-align-"
                            style=""
                        >
                            <span class="woocommerce-Price-amount amount">
                                <bdi>
                                    <span class="woocommerce-Price-currencySymbol">
                                        {{ this.wpAttributes.currency }}
                                    </span>
                                    &nbsp;{{ formattedPrice }}
                                </bdi>
                            </span>
                        </div>
                    </div>

                    <div
                        data-block-name="woocommerce/add-to-cart-form"
                        class="wp-block-add-to-cart-form wc-block-add-to-cart-form wc-block-add-to-cart-form--input wp-block-woocommerce-add-to-cart-form"
                    >
                        <div class="quantity">
                            <input
                                ref="quantityInputField"
                                type="number"
                                class="input-text qty text"
                                name="quantity"
                                value="1"
                                aria-label="Produktmenge"
                                min="1"
                                max=""
                                step="1"
                                placeholder=""
                                inputmode="numeric"
                                autocomplete="off"
                                style="min-width: 100px"
                            />
                        </div>
                    </div>
                </div>
                <!-- <div class="testing">
                    <p>Testing only</p>
                    <p><button @click="downloadBitmap">Download BMP</button></p>
                    <p><button @click="downloadImage">Download PNG</button></p>
                </div>
                <br /> -->
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from "vue";
import VueZoomable from "vue-zoomable";
import { Buffer } from "buffer";
import bmp from "bmp-js";
import "vue-zoomable/dist/style.css";
window.Buffer = Buffer; // Workaround for image-bmp package

export default {
    name: "PreviewControl",
    components: { VueZoomable },
    setup() {
        const config = inject("config");
        const wpAttributes = inject("wpAttributes");

        return { config, wpAttributes };
    },
    data() {
        return {
            previewImage: null,
            distortedImage: null,
            designerVariant: null,

            isPortrait: false,
        };
    },
    computed: {
        formattedPrice() {
            const price = this.wpAttributes.productPrice;
            return Number(price).toFixed(2);
        },
    },
    methods: {
        setImageBase64(base64) {
            this.distortedImage = new Image();
            this.distortedImage.src = base64;
            this.updateImage();
        },

        getImage() {
            return this.distortedImage;
        },

        getImageBase64() {
            return this.distortedImage.src;
        },

        updateImage() {
            const imgRef = this.$refs.image;
            const base64 = this.getImageBase64();

            // Create a temporary canvas for resizing
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            let output_width = 0;
            let output_height = 0;

            if (this.isPortrait) {
                output_width = this.designerVariant.output_image.height / this.designerVariant.scale_factor.height;
                output_height = this.designerVariant.output_image.width / this.designerVariant.scale_factor.width;
            } else {
                output_width = this.designerVariant.output_image.width / this.designerVariant.scale_factor.width;
                output_height = this.designerVariant.output_image.height / this.designerVariant.scale_factor.height;
            }

            canvas.width = output_width;
            canvas.height = output_height;

            // Create a new Image object to ensure proper loading
            const tempImage = new Image();
            tempImage.src = base64;

            tempImage.onload = () => {
                // Draw the image onto the resized canvas
                ctx.drawImage(tempImage, 0, 0, output_width, output_height);

                // Update the image source with the resized version
                imgRef.src = canvas.toDataURL("image/png");
            };
        },

        getBitmapBase64() {
            return new Promise((resolve, reject) => {
                // Create a new image element
                const image = new Image();
                image.src = this.getImageBase64();

                // When the image is loaded, draw it onto the canvas
                image.onload = () => {
                    // Create a canvas element
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Set canvas size to the image size
                    canvas.width = image.width;
                    canvas.height = image.height;

                    // Draw the image onto the canvas
                    ctx.drawImage(image, 0, 0);

                    // Get the image data from the canvas (RGBA format)
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const pixelData = imageData.data;
                    const bmpData = this.convertToBmp(canvas.width, canvas.height, pixelData);

                    // Return BMP data as Base64
                    const base64Bmp = "data:image/bmp;base64," + Buffer.from(bmpData).toString("base64");
                    resolve(base64Bmp);
                };

                image.onerror = (err) => {
                    console.error(err);
                };
            });
        },

        downloadBitmap() {
            // Create a new image element
            const image = new Image();

            // When the image is loaded, draw it onto the canvas
            image.onload = () => {
                // Create a canvas element
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Set canvas size to the image size
                canvas.width = image.width;
                canvas.height = image.height;

                // Draw the image onto the canvas
                ctx.drawImage(image, 0, 0);

                // Get the image data from the canvas (RGBA format)
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const pixelData = imageData.data;
                const bmpData = this.convertToBmp(canvas.width, canvas.height, pixelData);

                // Convert the canvas to a BMP format (we'll use image/bmp mime type)
                const blob = new Blob([bmpData], { type: "image/bmp" });

                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "image.bmp";
                link.click();
            };

            image.src = this.getImageBase64();
        },

        downloadImage() {
            // Create a new image element
            const image = new Image();

            // Ensure cross-origin images are handled properly (optional)
            image.crossOrigin = "anonymous";

            image.onload = () => {
                // Create a canvas element
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Set canvas size to the image size
                canvas.width = image.width;
                canvas.height = image.height;

                // Draw the image onto the canvas
                ctx.drawImage(image, 0, 0);

                // Convert canvas content to a Blob
                canvas.toBlob((blob) => {
                    if (blob) {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = "image.png";
                        link.click();

                        // Cleanup
                        URL.revokeObjectURL(link.href);
                    }
                }, "image/png");
            };

            // Set image source after defining onload
            image.src = this.getImageBase64();
        },

        convertToBmp(width, height, pixelData) {
            //rearrage from RGBA to BGRA
            for (let i = 0; i < pixelData.length; i += 4) {
                const r = pixelData[i];
                const g = pixelData[i + 1];
                const b = pixelData[i + 2];
                const a = pixelData[i + 3];

                pixelData[i] = a;
                pixelData[i + 1] = b;
                pixelData[i + 2] = g;
                pixelData[i + 3] = r;
            }

            const bmpData = bmp.encode({
                width: width,
                height: height,
                data: pixelData,
            });
            return bmpData.data; // Return the BMP binary data
        },

        getQuantity() {
            const quantity = this.$refs.quantityInputField.value;
            if (quantity === "" || isNaN(quantity) || quantity < 1) {
                return 1;
            } else {
                return Number(quantity);
            }
        },

        async addToCart() {
            //post request to add to cart
            const url = this.wpAttributes.addToCartUrl === "" ? window.location.href : this.wpAttributes.addToCartUrl;
            const productId = this.wpAttributes.productId;
            const exportImage = await this.getImageBase64();
            const quantity = this.getQuantity();

            // Create a form dynamically
            const form = document.createElement("form");
            form.method = "POST";
            form.action = url;

            // Add the product ID field
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = "add-to-cart";
            input.value = productId;
            form.appendChild(input);

            // Add the quantity field
            const quantityInput = document.createElement("input");
            quantityInput.type = "hidden";
            quantityInput.name = "quantity";
            quantityInput.value = quantity;
            form.appendChild(quantityInput);

            // Add the image field
            const imageInput = document.createElement("input");
            imageInput.type = "hidden";
            imageInput.name = "image";
            imageInput.value = exportImage;
            form.appendChild(imageInput);

            // Append the form to the body and submit it
            document.body.appendChild(form);
            // console.log(form);
            form.submit();
        },

        async initControl(base64Image, isPortrait = false) {
            this.isPortrait = isPortrait;

            const colorCount = this.wpAttributes.colorCount;
            this.designerVariant = this.config.product_variants.find(
                (variant) => Number(variant.colors) === Number(colorCount)
            );

            // Set image
            this.distortedImage = new Image();
            this.distortedImage.src = base64Image;
            this.distortedImage.onload = () => {
                this.updateImage();
            };
        },
    },
};
</script>

<style>
.pc-preview-control-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
    /* border: 1px solid #00f; */
}

.pc-info-container {
    flex-grow: 1;
    /* border: 1px solid #f00; */
}

.pc-image-container {
    flex-grow: 1;
    position: relative;
    display: inline-block;
    max-height: 50vh;
    max-width: 50vw;
    /* border: 1px solid #000; */
}
.pc-image-wrapper {
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
    background-color: #f0f0f0;
    /* border: 1px solid #f00; */
}

.pc-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
    /* border: 1px solid #0f0; */
}

/* .testing {
    color: red;
} */
</style>